import type { SearchResults as SearchResultsType } from '@readme/api/src/mappings/search/types';
import type { ProjectClientSide } from '@readme/backend/models/project/types';

import React, { useCallback, useContext, useEffect, useRef } from 'react';
import FocusLock from 'react-focus-lock';

import type { IProjectMetadata } from '@Hub/Search/types';

import { ProjectContext, VersionContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { useMetricsAPIFetcher } from '@core/hooks/useMetricsAPI';
import useReadmeApi from '@core/hooks/useReadmeApi';
import { useSearchStore, useSuperHubStore } from '@core/store';

import SearchBox from './SearchBox';
import SearchResults from './SearchResults';
import SearchTabs from './SearchTabs';
import styles from './style.module.scss';

interface Props {
  id: string;
  isModalOpen: boolean;
  projectsMeta: IProjectMetadata[];
  toggleModal: () => void;
}

const SuperHubSearch = ({ id, isModalOpen, projectsMeta, toggleModal }: Props) => {
  const bem = useClassy(styles, 'SuperHubSearch');
  const searchInput = useRef<HTMLInputElement | null>(null);

  const { project } = useContext(ProjectContext) as unknown as { project: ProjectClientSide };
  const { version } = useContext(VersionContext);

  const [prompt, updatePrompt, sectionFilter, buildDisplayedTabs, setResults] = useSearchStore(store => [
    store.prompt,
    store.updatePrompt,
    store.sectionFilter,
    store.buildDisplayedTabs,
    store.setResults,
  ]);

  const [apiBaseUrl, defaultApiOptions] = useSuperHubStore(store => [store.apiBaseUrl, store.defaultApiOptions]);
  const versionlessUrl = apiBaseUrl.split('/version')[0];
  const metricsFetch = useMetricsAPIFetcher({ version });

  const {
    data: resultsData = {
      data: [],
      total: 0,
    },
    isLoading: isSearchLoading,
  } = useReadmeApi<SearchResultsType>(
    prompt
      ? `${versionlessUrl}/search?version=${version}&query=${prompt}${sectionFilter ? `&section=${sectionFilter}` : ''}`
      : null,
    {
      ...defaultApiOptions,
    },
  );

  // Build available modules for projects based on project metadata
  useEffect(() => {
    buildDisplayedTabs(projectsMeta);
  }, [buildDisplayedTabs, projectsMeta]);

  // Keep store in sync with results and loading states
  useEffect(() => {
    setResults(resultsData, isSearchLoading);
  }, [isSearchLoading, resultsData, setResults]);

  /**
   * On result selection, we want to track the search term and link clicked
   * in doc metrics and close the modal if a link was clicked
   */
  const handleResultSelection = useCallback(
    async ({ term, link }: { link?: string; term?: string }) => {
      const body = {
        search: term || searchInput?.current?.value,
        link: (link || '').split('#')[0],
        requestType: 'search',
      };

      try {
        await metricsFetch({
          path: 'create/search',
          method: 'POST',
          body,
        });
      } catch {
        // Fail silently
      }

      // If we have a link, it means we've clicked a result and should reset query + close modal
      if (link) {
        updatePrompt('');
        toggleModal();
      }
    },
    [metricsFetch, toggleModal, updatePrompt],
  );

  return (
    <div className={bem('&')} id={id} role="tabpanel" tabIndex={0}>
      <FocusLock autoFocus={false} className={bem('-col')} disabled={!isModalOpen}>
        <SearchBox
          inputRef={searchInput}
          isModalOpen={isModalOpen}
          onClickout={handleResultSelection}
          subdomain={project?.subdomain || ''}
        />
        <SearchTabs />
        <SearchResults onResultSelection={handleResultSelection} projectMetadata={projectsMeta} results={resultsData} />

        {/* TODO: Post-beta: Support filters for Enterprise search */}
      </FocusLock>
    </div>
  );
};

export default SuperHubSearch;
